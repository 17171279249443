import React, { useEffect } from "react"
import styled from "@emotion/styled"
import Button from "./Button"
import LocationSelect from "./LocationSelect"

import { Link } from "gatsby"

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
`

const Content = styled.div`
  padding: 3rem;
  background: #202020;
  border-radius: 10px;
  text-align: center;
`

const Secondary = styled.h4`
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
`

const Body = styled.p`
  font-size: 1.7rem;
  padding: 1.5rem 0;
  font-weight: 400;
  color: #fff;
`

const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #2196f3;
  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #2196f3;
    bottom: 0;
    left: 0;
  }
  &:hover {
    &::after {
      height: 2px;
    }
  }
`

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CtaUnavailable = ({ handleClick }) => {
  //Prevent scrolling when visible
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => (document.body.style.overflow = "unset")
  }, [])
  return (
    <Container>
      <Content>
        <SelectWrapper>
          <Secondary>Is this your city?</Secondary>
          <LocationSelect />
        </SelectWrapper>
        <Body>
          If you are interested in getting SkipTheDepot in your city,{" "}
          <StyledLink to="/contact" onClick={handleClick}>
            contact us!
          </StyledLink>
        </Body>
        <Button onClick={handleClick}>Close</Button>
      </Content>
    </Container>
  )
}

export default CtaUnavailable
