import React, { useContext } from "react"
import styled from "@emotion/styled"

import Seo from "./Seo"
import Nav from "./Nav"
import Footer from "./Footer"
import CtaToast from "./CtaToast"
import CtaUnavailable from "./CtaUnavailable"
import { ToastContext } from "../contexts/ToastContext"
import { LocationContext } from "../contexts/LocationContext"

/*
Main Light: #2dc7ff
Main Medium: #2196F3
Main Dark: #1976D2

Secondary: #ef5350
Tertiary: #ffca28


Text: #202020

Background: #202020
Alt Background: #191919
Footer Background: #131418

Max width for content: 1170px

*/

const Main = styled.main`
  position: relative;
  overflow: hidden;
`

// const Space = styled.div`
//   height: ${(props) => props.height}px;
// `

const Layout = ({ title, seoDesc, children }) => {
  const { cta, setCta } = useContext(ToastContext)
  const { loc, handleSetLoc } = useContext(LocationContext)

  return (
    <>
      <Seo title={title} seoDesc={seoDesc} />
      {cta && <CtaToast setCta={setCta} />}
      {/* {cta && <Space height={ctaHeight} />} */}
      {!loc.serviced && (
        <CtaUnavailable
          handleClick={() => handleSetLoc("academy", "alberta")}
        />
      )}
      <Main>
        <Nav loc={loc} />
        {children}
        <Footer />
      </Main>
    </>
  )
}

export default Layout
