import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"

import { Link, useStaticQuery, graphql } from "gatsby"
import { LocationContext } from "../contexts/LocationContext"
import { EmailContext } from "../contexts/EmailContext"
import SocialMedia from "./SocialMedia"
import LocationSelect from "./LocationSelect"
import Mail from "../../static/img/svg/mail.svg"
import Phone from "../../static/img/svg/phone.svg"
import Pin from "../../static/img/svg/pin.svg"
import Globe from "../../static/img/svg/globe.svg"

const Container = styled.footer`
  background: #202020;
  width: 100%;
`

const Content = styled.div`
  max-width: 130rem;
  padding: 3rem 1.5rem;
  margin: 0 auto 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media only screen and (max-width: 32.5em) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
`

const Main = styled.div`
  grid-column: 1 / 3;
  svg {
    height: 4rem;
    margin-right: 2rem;
    @media only screen and (max-width: 32.5em) {
      height: 5rem;
    }
  }
  @media only screen and (max-width: 32.5em) {
    grid-column: 1 / -1;
  }
`

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`

const NavLink = styled.li`
  margin: 0.5rem 0;
`

const To = styled(Link)`
  text-decoration: none;
  font-size: 1.7rem;
  color: #999;
  &:hover {
    color: #fff;
  }
  @media only screen and (max-width: 40em) {
    font-size: 1.7rem;
  }
`

const Brand = styled.img`
  object-fit: contain;
  padding: 0.5rem 0;
`

const Sub = styled.div`
  margin-top: 3rem;
  padding: 1rem 0 0;
  grid-column: 1/ -1;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #999;
`

const Secondary = styled.li`
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
`

const Body = styled.span`
  font-size: 1.7rem;
  color: #999;
  grid-column: 1/-1;

  span:not(:last-child)::after {
    content: " | ";
  }
`

const Info = styled.span`
  display: flex;
  height: 2.5rem;
  margin: 1.5rem 0;
  align-items: center;
  color: #999;
  font-size: 1.7rem;
  a,
  span {
    cursor: pointer;
    color: #999;
    text-decoration: none;
  }
  svg {
    fill: #fff;
    width: 2rem;
  }
`

const BadgeLink = styled.a`
  display: inline-block;
  margin: 1rem 1rem 1rem 0;
  @media only screen and (max-width: 31.5em) {
    display: flex;
    align-items: center;
    margin: 0;
    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`

const Picture = styled.picture`
  height: 50px;
  @media only screen and (max-width: 31.5em) {
    height: auto;
    width: 150px;
    img {
      height: auto;
      width: 100%;
    }
  }
  img {
    height: 100%;
  }
`

const ExternalLink = styled.a`
  color: #999;
  &:hover {
    color: #fff;
  }
`

const Footer = () => {
  const { loc } = useContext(LocationContext)
  const { setShowEmail } = useContext(EmailContext)
  const [address, setAddress] = useState("")

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
        frontmatter {
          map {
            data {
              name
              address
            }
          }
        }
      }
    }
  `)

  const mapData = data.markdownRemark.frontmatter.map.data

  useEffect(() => {
    // If the user is currently located in a serviced area
    if (mapData.length > 1 && loc.area) {
      let selectedAddress = false
      let tentative

      for (let city of mapData) {
        let processedName = city.name.toLowerCase().replace(/\s/g, "")
        let matchesCity = processedName.includes(loc.city.toLowerCase())
        let matchesArea = processedName.includes(loc.area.toLowerCase())

        // If the current (depot) iteration is a city equivalent of the currently selected location then set this as the address
        if (matchesCity) {
          setAddress(city.address)
          selectedAddress = true
          break
        } else if (matchesArea) {
        /* If the current (depot) iteration is an area equivalent of the currently selected location then it is possible that this is the address if the loop does not 
        find a city equivalence on a later iteration hence it is the tentative address */
          tentative = city.address
        }
      }

      // If there exists a tentative address and an official address has not been set, then set the tentative address as the official address
      if (tentative && !selectedAddress) {
        setAddress(tentative)
      }
    } else {
      setAddress(mapData[0].address)
    }
  }, [loc])

  return (
    <Container>
      <Content>
        <Main>
          <Brand
            src="/img/svg/skipTheDepot-full.svg"
            width={300}
            height={40}
            alt="SkipTheDepot Logo"
          />
          <Info>
            <Globe />
            <LocationSelect color="#999" />
          </Info>
          <Info>
            <Pin />
            {address}
          </Info>
          <Info onClick={() => setShowEmail(true)}>
            <Mail />
            <span>Email us</span>
          </Info>
          <Info>
            <Phone />
            {["calgary", "lethbridge"].includes(loc.area) && (
              <a href="tel:4034763677">403-476-3677</a>
            )}
            {loc.area === "edmonton" && (
              <a href="tel:7803283865">780-328-3865</a>
            )}
          </Info>
          <BadgeLink href="https://apps.apple.com/ca/app/skipthedepot/id1359990960">
            <Picture>
              <source
                srcSet="/img/app-store-badge-50px.webp"
                type="image/webp"
              />
              <img
                src="/img/app-store-badge-50px.png"
                alt="Download on the App Store"
                loading="lazy"
              />
            </Picture>
          </BadgeLink>
          <BadgeLink href="https://play.google.com/store/apps/details?id=com.skipthedepot.app&hl=en_US">
            <Picture>
              <source
                srcSet="/img/google-play-badge-50px.webp"
                type="image/webp"
              />
              <img
                src="/img/google-play-badge-50px.png"
                alt="Get it on Google Play"
                loading="lazy"
              />
            </Picture>
          </BadgeLink>
        </Main>
        <NavLinks>
          <Secondary>Learn more</Secondary>
          <NavLink>
            <To to="/recycling">Recycling</To>
          </NavLink>
          <NavLink>
            <To to="/pricing">Pricing</To>
          </NavLink>
          <NavLink>
            <To to="/services">Services</To>
          </NavLink>
          <NavLink>
            <To to="/locations">Locations</To>
          </NavLink>
          <NavLink>
            <To to="/bottle-depot">Bottle Depot</To>
          </NavLink>
          <NavLink>
            <To to="/blog">Blog</To>
          </NavLink>
        </NavLinks>
        <NavLinks>
          <Secondary>About us</Secondary>
          <NavLink>
            <To to="/faq">FAQ</To>
          </NavLink>
          <NavLink>
            <To to="/contact">Contact</To>
          </NavLink>
          <NavLink>
            <To to="/contact/drive-with-us">Drive with us</To>
          </NavLink>
          <NavLink>
            <To to="/contact/work-with-us">Work with us</To>
          </NavLink>
          <NavLink>
            <To to="/media">Media</To>
          </NavLink>
        </NavLinks>

        <Sub>
          <Body>
            <span>&copy;SkipTheDepot {new Date().getFullYear()}</span>
            <span>
              <ExternalLink
                href="https://app.skipthedepot.com/terms"
                target="_blank"
              >
                Terms & Conditions
              </ExternalLink>
            </span>
            <span>
              <ExternalLink
                href="https://app.skipthedepot.com/privacy"
                target="_blank"
              >
                Privacy Notice
              </ExternalLink>
            </span>
          </Body>
          <SocialMedia height={20} textcolor="#999" />
        </Sub>
      </Content>
    </Container>
  )
}

export default Footer
