import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title, seoDesc }) => {
  const schemaOrg = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    name: "SkipTheDepot",
    image: "http://skipthedepot.com/img/SKIPTHEDEPOT_APP_ICON.svg",
    url: "https://skipthedepot.com/",
    sameAs: [
      "https://twitter.com/skipthedepot",
      "https://www.facebook.com/skipthedepot",
      "https://www.linkedin.com/company/skipthedepot",
      "https://www.instagram.com/skipthedepot/",
    ],
    author: {
      "@type": "Person",
      name: "LessThan3 Inc.",
    },
    datePublished: "2019-12-09",
    publisher: {
      "@type": "Organization",
      name: "LessThan3 Inc.",
    },
    applicationCategory: "Utilities",
    downloadUrl: "https://app.skipthedepot.com/",
    operatingSystem: "Android, iOS, MacOS, Windows",
    screenshot: "http://skipthedepot.com/img/phone/phone-3.png",
    softwareVersion: "2.7.0",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.6",
      bestRating: "5",
      ratingCount: "163",
    },
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "CAD",
    },
  }

  useEffect(() => {
    // Load google tag manager script
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "AW-1007387135")
  }, [])

  return (
    <Helmet>
      <html lang="en" />
      <meta charset="UTF-8" />
      <title>{title}</title>
      <meta name="description" content={seoDesc} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://skipthedepot.com" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://skipthedepot.com/skipthedepot.jpg"
      />
      <meta property="og:description" content={seoDesc} />
      <meta property="og:site_name" content="SkipTheDepot" />

      <meta
        name="facebook-domain-verification"
        content="ayznmcu728erpklt0z1kactsu9357o"
      />

      {/* iOS Smart App Banner */}
      {/* <meta name="apple-itunes-app" content="app-id=1359990960" /> */}

      {/* Favicon Stuff */}
      <meta name="msapplication-TileColor" content="#2196f3" />
      <meta name="theme-color" content="#2196f3" />
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-T96QK2V');`}</script>
      <meta
        name="google-site-verification"
        content="saGRQEBn_hWBdOeiz6ZPZxweaR0oNoyYWiIA31DpV30"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2196f3" />
      <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-1007387135"
      ></script>
    </Helmet>
  )
}

export default Seo
