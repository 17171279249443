export const LOCATIONS = {
  alberta: [
    "Academy",
    "Airdrie",
    "Balzac",
    "Banff",
    "Beaumont",
    "Blackfalds",
    "Bragg Creek",
    "Calgary",
    "Canmore",
    "Chestermere",
    "Coaldale",
    "Coalhurst",
    "Cochrane",
    "De Winton",
    "Edmonton",
    "Fort McMurray",
    "Fort Saskatchewan",
    "Innisfail",
    "Leduc",
    "Lethbridge",
    "Nisku",
    "Okotoks",
    "Red Deer",
    "Sherwood Park",
    "Spruce Grove",
    "St Albert",
    "Stoney Plain",
  ],
  //   ontario: ["Toronto", "Ottowa"],
}

export const PROVINCES = {
  alberta: "AB",
  //   ontario: "ON",
}
