import React, { useContext } from "react"
import styled from "@emotion/styled"
import { LocationContext } from "../contexts/LocationContext"

import { LOCATIONS, PROVINCES } from "../constants"

const Select = styled.select`
  color: ${(props) => props.color || "#fff"};
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: ${(props) => props.fontSize || "1.9rem"};
  line-height: 1.6;
  display: block;
  padding: 0;
  margin: 0;
  width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @media only screen and (max-width: 31.5em) {
    width: 92px;
  }
`

const OptGroup = styled.optgroup`
  font-weight: 400;
  text-transform: capitalize;
`

const Option = styled.option`
  color: black;
`

const LocationSelect = ({ fontSize, color }) => {
  const { loc, handleSetLoc } = useContext(LocationContext)

  function handleChange(e) {
    const { value } = e.target
    const [city, province] = value.split("|")
    handleSetLoc(city, province)
  }

  function toCamelCase(string) {
    string = string
      .toLowerCase()
      .replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase()
      })
    return string.charAt(0).toLowerCase() + string.substring(1)
  }

  function toSentenceCase(string) {
    const text = string.replace(/([A-Z])/g, " $1")
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  return (
    <Select
      fontSize={fontSize}
      color={color}
      value={`${loc.city}|${loc.province}`}
      onChange={handleChange}
    >
      {Object.keys(LOCATIONS).map((province) => {
        return (
          <OptGroup label={toSentenceCase(province)}>
            {LOCATIONS[province].map((location, count) => (
              <Option value={`${toCamelCase(location)}|${province}`} key={count}>
                {location}, {PROVINCES[province]}
              </Option>
            ))}
          </OptGroup>
        )
      })}
    </Select>
  )
}

export default LocationSelect
